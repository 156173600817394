import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Saves data
   * @param key 
   * @param value 
   */
  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * Gets data
   * @param key 
   * @returns  
   */
  public getData(key: string) {
    return localStorage.getItem(key)
  }
  /**
   * Removes data
   * @param key 
   */
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Clears data
   */
  public clearData() {
    localStorage.clear();
  }

  /**
   * Sets token
   * @param token
   */
  setToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  /**
    * Gets token
    * @returns token
    */
  getToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  /**
    * Removes token
    * @returns
    */
  removeToken() {
    return localStorage.removeItem('accessToken');
  }
}

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  /**
   * Common helper for whole project
   */
  export class CommonHelper {
   constructor() {

   }
  }
import { configureChains, createConfig } from '@wagmi/core';
import { disconnect } from '@wagmi/core'
import { watchAccount } from '@wagmi/core';

import { Injectable, } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResponseInterface, User } from '../helpers/common.interface';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash'; 
import { CommonHelper } from '../helpers/common';

const chains: any = environment.SUPPORTED_CHAINS;
const projectId = 'ce7c7bb0dcadf584586f578ba078becb';

@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {
  walletAddress: any
  public connector: any; // Private property to store the connector instance
  txnHash: any;
  private supportedChainId = environment.SUPPORTED_CHAINID;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  constructor(private toastr: ToastrService, private authServices: AuthService,
    private router: Router, private localStorageService: LocalStorageService,
    private location: Location,
    private commonHelper:CommonHelper,
  ) {
    const userData: any = localStorage.getItem('admin');
    const parsedUserData = userData ? JSON.parse(userData) : null;
    const initialUserValue = parsedUserData ? parsedUserData : /* Default user object */ {};

    this.currentUserSubject = new BehaviorSubject<User>(initialUserValue);
    this.currentUser = this.currentUserSubject.asObservable();
  }
  

  /**
   * check connetctiion
   * @param{string}route
   */

  // async checkConnection(route: string) {
  //   // Configure Chains and create a publicClient
  //   const { publicClient } = configureChains(chains, [
  //     w3mProvider({ projectId }),
  //   ]);

  //   // Create a wagmiConfig with specific configuration options
  //   const wagmiConfig = createConfig({
  //     autoConnect: true,
  //     connectors: w3mConnectors({ projectId, chains }),
  //     publicClient,
  //   });

  //   // Create an EthereumClient instance using the wagmiConfig and chains
  //   const ethereumClient = await new EthereumClient(wagmiConfig, chains);
  //   this.connector = await new Web3Modal({ projectId }, ethereumClient);
  //   let account: any = localStorage.getItem('wagmi.store');
  //   account = JSON.parse(account);
  //   this.walletAddress = account.state.data.account;

  //   watchAccount((account: any) => {
  //     const connectedChain = account.connector?.chains[0]?.id;
  //     if (this.supportedChainId != connectedChain && connectedChain !== undefined) {
  //       this.toastr.error('Connect to the supported chain');
  //       this.router.navigate(['/'])
  //     }

  //     if (account['address'] === undefined) {
  //       this.router.navigate(['/'])
  //       this.localStorageService.clearData()
  //     }
  //     else if (account['isConnected']) {
  //       this.authServices.logIn(account['address']).subscribe({
  //         next: (response: ApiResponseInterface<null>) => {
  //           console.log('login response', response);
  //           let user: any;
  //           if (response.data.admin.is_primary) {
  //             user = {
  //               name: environment.ADMIN_NAME,
  //               email: environment.ADMIN_EMAIL,
  //               wallet_address: response.data.admin.wallet_address,
  //               is_admin: true,
  //             }
  //           } else {
  //             user = {
  //               name: response.data.admin.name,
  //               email: response.data.admin.email,
  //               wallet_address: response.data.admin.wallet_address,
  //               is_admin: false,
  //             }
  //           }
  //           let userData:any = localStorage.getItem('admin');
  //           const previousUserData = JSON.parse(userData);
  //           const currentUserData = user;
  //           const shouldUpdateUserData:boolean = !(_.isEqual(previousUserData,currentUserData))
  //           const isLoginPage:boolean = this.location.path() === '';
            
  //           // NOTE : Redirect and update the user data if:
  //           // 1. The user data has changed (new account tries to login)
  //           // 2. There is no logged-in account (previousUserData is null/undefined)
  //           // 3. The current page is the login page
  //           if(shouldUpdateUserData || !previousUserData || isLoginPage){
  //             this.currentUserSubject.next(user);
  //             const adminString = JSON.stringify(user);
  //             localStorage.setItem('admin', adminString);
  //             localStorage.setItem('auth_token', response.data.session.session_token);
  //             this.toastr.success(response?.message);
  //             if (response.data.admin.is_primary) {
  //               this.router.navigate(['/dashboard']);
  //             } else {
  //               this.router.navigate(['/transaction-management']);
  //             }
  //           }
  //         },
  //         error: (err: any) => {
  //           const errorMessage = err?.message ?? err?.err?.message
  //           this.toastr.error(errorMessage)
  //           this.router.navigate(['/'])
  //           this.localStorageService.clearData()
  //           disconnect();
  //         }
  //       })
  //     } else if (!this.walletAddress) {
  //       this.router.navigate(['/'])
  //     }
  //   })
  // }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

}



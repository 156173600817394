import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseInterface } from '../helpers/common.interface';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private walletAddressSubject = new BehaviorSubject<string>('');
  walletAddress$ = this.walletAddressSubject.asObservable();
  
  constructor(private http:HttpClient) { }

  /**
   * Logs in
   * @param loginData
   * @returns in
   */
  logIn(wallet_address:string):Observable<ApiResponseInterface>{
    return this.http.post<ApiResponseInterface>(`${environment.API_BASE_URL}login`, { wallet_address: wallet_address} )
  }

  /**
   * Updates wallet address
   * @param address 
   */
  updateWalletAddress(address: string) {
    this.walletAddressSubject.next(address);
  }
}

import { holesky } from "viem/chains";

export const environment = {
  USER_BASE_URL:'http://54.162.203.46/network-smetx-user-dev',
  API_BASE_URL:'https://devapi.sunwavecoin.com/api/v1/admin/',
  PROVIDER: 'https://ethereum-holesky.blockpi.network/v1/rpc/public',
  CONTRACT_ADDRESS:'0xDa4035A986A66e597B8d6a2a1ea4ceC2638CE2C7',
  USDT_TOKEN: '0x94595E3fA97812a34A53785924916B7A7E1744ea',
  SUPPORTED_CHAINS: [holesky],
  SUPPORTED_CHAINID: '17000',
  TRANSACTION_HASH_BASEURL: 'https://holesky.etherscan.io/tx',
  WALLET_CONNECT_PROJECT_ID: '66932ffba082b546f31afb2089524bb9',
  APP_NAME: 'Sunwave',
  APP_DESCRIPTION: 'Sunwave Public Sale',
  APP_URL: 'https://sunwave.com',
  VESTING_CONTRACT: '0xDa4035A986A66e597B8d6a2a1ea4ceC2638CE2C7',
  CHAIN_ID:17000
};
